import React, { FC } from 'react';
import cn from 'classnames';
import { FiAlertCircle, FiClock, FiXCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { TModertionStatusLiteral } from '../../../domains/creative/creatives/statuses';
import css from '../../../domains/campaign/Additional/Links/styles.module.scss';
import CheckCircle from '../../../assets/check-circle.svg';

type Props = {
  status: TModertionStatusLiteral;
};

const getTdStatusIcon = (status: TModertionStatusLiteral) => {
  if (
    !status ||
    status === 'AWAITING_DATA_COMPLETION' ||
    status === 'UNKNOWN' ||
    status === 'READY'
  ) {
    return <FiAlertCircle size={22} color="#FF9C08" />;
  }

  if (status === 'ON_MODERATION') {
    return <FiClock size={22} color="#FF9C08" />;
  }

  if (status === 'APPROVED') {
    return <ReactSVG src={CheckCircle} className={css.CheckCircleIcon} />;
  }

  return <FiXCircle size={22} color="#FF3636" />;
};

const ModerationStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <div className={css.param}>
      <div className={css.name}>
        {t('campaigns_page.campaign_settings.additional.links.table.status')}:
      </div>
      <div className={css.value}>
        <div className={cn(css.mod_status, `color-${status}`)}>
          {getTdStatusIcon(status)}
          <div className={css.text}>
            {t(`creative.moderation.statuses.${status.toLowerCase()}`)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModerationStatus;
