import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import Card from 'components/UI/Card';
import Tooltip from 'components/UI/Tooltip';
import moment from 'moment';
import { isFieldInConfig } from 'utils/isFieldInConfig';
import { useCreativeTypes } from 'domains/creative/model/selectors';
import { FiDownload } from 'react-icons/fi';
import CollapseCard from 'components/UI/CollapseCard';
import copySvg from './icons/copy.svg';
import css from './styles.module.scss';
import { useExternalModeration, useGetLinksReport } from './hook';
import { TModertionStatusLiteral } from '../../../creative/creatives/statuses';
import Loader from '../../../../components/UI/Loader';
import ModerationStatus from '../../../../components/UI/ModerationStatus';
import { CreativeDetail, TCreativeData } from '../../../creative/types';
import { isGardVideoInlineType } from '../../../creative/creatives/hooks';
import EmptyLink from './EmptyLink';

const Links: FC = () => {
  const { t } = useTranslation();

  const {
    tabIndex,
    setTabIndex,
    externalModeration,
    isLoading,
    campaign_xxhash,
    copyModerationDisapproved,
    creativeList,
    isLoadingCreative,
  } = useExternalModeration();

  const { data: creativeTypesArr } = useCreativeTypes();

  const [tab, setTab] = useState<boolean>(false);

  const { onGetReport, disGetReport } = useGetLinksReport(
    creativeList,
    creativeTypesArr,
  );

  const LinksItem: FC<{ data: CreativeDetail }> = ({
    data: {
      creative_id,
      type,
      status,
      title,
      pixel,
      pixel_additional,
      link,
      xxhash,
      data,
      date_create,
      date_edit,
      config_values,
      extension_link,
      adomain,
    },
  }) => {
    const keysAudit = ['show', 'click'];
    const config = creativeTypesArr?.find((item) => item.key === type);
    const configArr = (config && Object.values(config.config_fields)) || [];
    const erid = isFieldInConfig(configArr, 'erid');
    const JSAudit = isFieldInConfig(configArr, 'js_code_audit');
    const clickAudit = isFieldInConfig(configArr, 'click_audit_pixel');

    return (
      <div className={css.item}>
        <div className={css.number}>{creative_id}</div>
        <div className={css.type}>
          {creativeTypesArr
            ?.filter(({ key }) => key === type)
            .map(({ icon, key, id }) => (
              <Tooltip
                title={<img src={icon} className="icon" alt={key} />}
                key={id}
              >
                <p>{t(`creative.create_menu.${key}`)}</p>
              </Tooltip>
            ))}
        </div>
        <div className={cn(css.status, `bg-${status}`)} />
        <div className={css.data}>
          <div className={css.title}>
            <Link
              to={`/main/campaign/${campaign_xxhash}/creatives/${xxhash}/basic_settings`}
            >
              {title}
            </Link>
          </div>
          <div className={css.links}>
            {erid && (
              <div className={css.link}>
                <div className={css.name}>{erid.title}</div>
                <div className={css.value}>{config_values[erid.id] || '-'}</div>
              </div>
            )}
            <div className={css.link}>
              <div className={css.name}>
                {`${t('creative.creative_was_created')}: `}
                {date_create && date_create.date ? (
                  <>{moment(date_create.date).format('DD.MM.YYYY HH:mm')}</>
                ) : (
                  <>
                    <br />-
                  </>
                )}
              </div>
              <div className={css.value}>
                {`${t('creative.creative_was_edited')}: `}
                {date_edit && date_edit.date ? (
                  <>{moment(date_edit.date).format('DD.MM.YYYY HH:mm')}</>
                ) : (
                  <>
                    <br />-
                  </>
                )}
              </div>
            </div>
            <div className={css.link}>
              <div className={css.name}>
                {t(
                  'campaigns_page.campaign_settings.additional.links.table.link',
                )}
              </div>
              <div className={css.value}>{link || '-'}</div>
            </div>
            <div className={css.link}>
              <div className={css.name}>
                {t('creative.create_creative.Extension_link')}
              </div>
              <div className={css.value}>{extension_link || '-'}</div>
            </div>
            {clickAudit && (
              <div className={css.link}>
                <div className={css.name}>
                  {t(`creative.${clickAudit.field}`)}
                </div>
                <div className={css.value}>
                  {config_values[clickAudit.id] || '-'}
                </div>
              </div>
            )}
            <div className={css.link}>
              <div className={css.name}>
                {t('creative.create_creative.Domain')}
              </div>
              <div className={css.value}>{adomain || '-'}</div>
            </div>
            <div className={css.link}>
              <div className={css.name}>
                {t(
                  'campaigns_page.campaign_settings.additional.links.table.pixel',
                )}
              </div>
              <div className={css.value}>{pixel || '-'}</div>
            </div>
            <div className={css.link}>
              <div className={css.name}>
                {`${t(
                  'campaigns_page.campaign_settings.additional.links.table.pixel_additional',
                )}:`}
              </div>
              <div className={css.value}>{pixel_additional || '-'}</div>
            </div>
            {JSAudit && (
              <div className={css.link}>
                <div className={css.name}> {t('creative.js_code_audit')}</div>
                <div className={css.value}>
                  {config_values[JSAudit.id] || '-'}
                </div>
              </div>
            )}
            {/* Под вопросом COMPANION */}
            {(type === 'VIDEO_INLINE' ||
              type === 'VIDEO_VAST' ||
              type === 'COMPANION') &&
              data &&
              isGardVideoInlineType(data) &&
              data.audit &&
              keysAudit &&
              keysAudit.map((key) => {
                if (data.audit[key]) {
                  return (
                    <div className={css.link} key={key}>
                      <div className={css.name}>
                        {t(`creative.video.fields.video_${key}`)}
                      </div>
                      <div className={css.value}>{data.audit[key]}</div>
                    </div>
                  );
                }
                return <div key={key} />;
              })}
          </div>
        </div>
      </div>
    );
  };

  const ApproveItem: FC<{
    data: TCreativeData;
    id: string;
    moderation: TModertionStatusLiteral;
  }> = ({
    data: { type, creative_id, status, title, xxhash },
    id,
    moderation,
  }) => (
    <div className={css.item}>
      <div className={css.number}>{creative_id}</div>
      <div className={css.type}>
        {creativeTypesArr
          ?.filter(({ key }) => key === type)
          .map(({ icon, key, id: typeId }) => (
            <Tooltip
              title={<img src={icon} className="icon" alt={key} />}
              key={typeId}
            >
              <p>{t(`creative.create_menu.${key}`)}</p>
            </Tooltip>
          ))}
      </div>
      <div className={cn(css.status, `bg-${status}`)} />
      <div className={css.title}>
        <Link
          to={`/main/campaign/${campaign_xxhash}/creatives/${xxhash}/basic_settings`}
        >
          {title}
        </Link>
      </div>
      <div className={css.moderation}>
        <ModerationStatus status={moderation} />
        <div className={css.param}>
          <div className={css.name}>ID:</div>
          <div className={css.value}>{id || '-'}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={css.wrapper}>
      <Card className={css.card} noHover>
        <CollapseCard
          updateHeight={[tab, !isLoadingCreative]}
          title={
            <>
              <h3>
                {t('campaigns_page.campaign_settings.additional.links.title')}
                {tabIndex !== 0 &&
                  externalModeration &&
                  externalModeration.google.length !== 0 && (
                    <div
                      className={cn(css.copy, 'CollapseCard_hide')}
                      onClick={copyModerationDisapproved}
                    >
                      <Tooltip
                        title={
                          <div className={css.button}>
                            <ReactSVG src={copySvg} />
                          </div>
                        }
                        left
                        hintWidth={210}
                      >
                        <p>
                          {t(
                            'campaigns_page.campaign_settings.additional.links.tooltip',
                          )}
                        </p>
                      </Tooltip>
                    </div>
                  )}
              </h3>
            </>
          }
        >
          <Tabs
            className={css.tabs}
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList className={css.tablist}>
              <Tab className={css.tab} onClick={() => setTab(!tab)}>
                <span>
                  {t(
                    'campaigns_page.campaign_settings.additional.links.tabs.links',
                  )}
                </span>
              </Tab>
              <Tab className={css.tab} onClick={() => setTab(!tab)}>
                <span>
                  {t(
                    'campaigns_page.campaign_settings.additional.links.tabs.google',
                  )}
                </span>
              </Tab>
            </TabList>
            <TabPanel className={css.panel}>
              <div className={cn(css.table, 'isLinksTable')}>
                {isLoadingCreative && <Loader />}
                {!isLoadingCreative &&
                  creativeList &&
                  creativeList.map((item) => (
                    <LinksItem
                      key={item.xxhash + item.internal_id}
                      data={item}
                    />
                  ))}
                {creativeList && creativeList.length === 0 && <EmptyLink />}
              </div>
            </TabPanel>
            <TabPanel className={css.panel}>
              <div className={cn(css.table, 'isApproveTable')}>
                {isLoading && <Loader />}
                {!isLoading &&
                  externalModeration &&
                  externalModeration.google.map(
                    ({ creativeData, external_id, status }) => (
                      <ApproveItem
                        data={creativeData}
                        id={external_id}
                        moderation={status}
                      />
                    ),
                  )}
                {externalModeration &&
                  externalModeration.google.length === 0 && <EmptyLink />}
              </div>
            </TabPanel>
            <Tooltip
              title={<FiDownload size={24} />}
              className={css.download_btn}
              onClick={onGetReport}
              isDisabled={disGetReport}
            >
              {t(`download_btn`)}
            </Tooltip>
          </Tabs>
        </CollapseCard>
      </Card>
    </div>
  );
};

export default Links;
