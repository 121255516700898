import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import Tooltip from 'components/UI/Tooltip';
import { FiDownload, FiRefreshCcw } from 'react-icons/fi';
import DateRangeFilter from 'components/DateRangeFilter';
import { parsingDate } from 'utils/momentParse';
import moment from 'moment';
import NavigationHeader from 'components/NavigationHeader';
import GeneralReportTable from 'components/GeneralReportTable';
import { KeysSettings, useStateValue } from 'utils/context';
import { useParams } from 'react-router';
import { useDispatchApp } from 'redux/rootSelectors';
import { Period } from 'types/general';
import debounce from 'lodash/debounce';
import ErrorReport from 'components/Errors/Reports';
import css from './styles.module.scss';
import { useGetColumns } from './model/useGetColumns';
import { fetchClientsStat } from '../../reducer';
import { useClientsStat } from '../../model/selectors';
import { useGetClientsStatReport } from './model/useGetReport';
import { ReactComponent as UsersSvg } from '../../../../assets/UsersCheck.svg';

const AgencyReport: FC = () => {
  const { t, i18n } = useTranslation();
  const { isThemeExtended, isThemeFull } = useUserTheme();

  const dispatch = useDispatchApp();

  const { data: user } = useUserInfo();
  const { id: partner_xxhash } = useParams<{ id: string }>();
  const { state, dispatch: dispatchContext } = useStateValue();

  const [activeFilterStatuses, setActiveFilterStatuses] = useState<string[]>([
    'LAUNCHED',
    'STOPPED',
  ]);

  const [showTree, setShowTree] = useState<boolean>(false);

  const statusArr = [
    {
      title: t('campaigns_page.campaigns_black_header_main.act'),
      status: 'LAUNCHED',
    },
    {
      title: t('campaigns_page.campaigns_black_header_main.pause'),
      status: 'STOPPED',
    },
    {
      title: t('campaigns_page.campaigns_black_header_main.arch'),
      status: 'ARCHIVE',
    },
  ];

  const settings = state.settings[KeysSettings.AGENCY_REPORT];

  const { columns } = useGetColumns({
    partner_xxhash,
    language: i18n.language,
  });

  const { data, error, isLoading: isLoadingClientsStat } = useClientsStat();

  const transformedStatistic = useMemo(
    () =>
      Object.values(data || {}).filter((item) => item.internal_id !== 'total'),
    [data],
  );
  const totalValues = data?.total || null;

  const { disGetReport, onGetReport } = useGetClientsStatReport(
    transformedStatistic,
    totalValues,
    columns,
  );

  const debounceStatus = debounce(
    (argArr: string[], show: boolean) =>
      dispatch(
        fetchClientsStat({
          partner_xxhash_list: [partner_xxhash],
          period: settings.period,
          status_list_clients: argArr,
          target: show ? 'au_shop' : null,
        }),
      ),
    1000,
  );

  const debounceStatusFeatcher = useCallback(
    (stat) => debounceStatus(stat, showTree),
    [settings.period, showTree],
  );

  const statusFilterClickHandler = (currentStatus: string) => {
    const isActive = activeFilterStatuses.includes(currentStatus);
    if (isActive) {
      const filtredStatArr = activeFilterStatuses.filter(
        (status) => status !== currentStatus,
      );
      debounceStatusFeatcher(filtredStatArr);
      setActiveFilterStatuses(filtredStatArr);
      return;
    }
    const currentStatArr = [...activeFilterStatuses, currentStatus];
    debounceStatusFeatcher(currentStatArr);
    setActiveFilterStatuses(currentStatArr);
  };

  const onUpdateStatistic = (period: Period) => {
    if (partner_xxhash) {
      dispatch(
        fetchClientsStat({
          partner_xxhash_list: [partner_xxhash],
          period,
          status_list_clients: activeFilterStatuses,
          target: showTree ? 'au_shop' : null,
        }),
      );
    }
  };

  const onUpdateDate = (period: Period, type: string) => {
    onUpdateStatistic(period);
    dispatchContext({
      type: 'setSettings',
      payload: {
        item: {
          type,
          period,
        },
        key: KeysSettings.AGENCY_REPORT,
      },
    });
  };

  const returnAgencyReportShowButton = (): JSX.Element => {
    const isOwnerOrReportsAgency =
      user?.group.includes('owner') && user?.group.includes('reports_agency');
    if (isOwnerOrReportsAgency) {
      return (
        <div
          className={cn(css.showTree, {
            _isShow: showTree,
          })}
          onClick={() => showAuShops()}
        >
          <Tooltip title={<UsersSvg />} bottom>
            {showTree ? 'Скрыть аудитории' : t(`agencies_page.show_audiences`)}
          </Tooltip>
        </div>
      );
    }
    return <></>;
  };

  const showAuShops = () => {
    const updatedShowTree = !showTree;
    setShowTree(updatedShowTree);

    dispatch(
      fetchClientsStat({
        partner_xxhash_list: [partner_xxhash],
        period: settings.period,
        status_list_clients: activeFilterStatuses,
        target: updatedShowTree ? 'au_shop' : null,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      fetchClientsStat({
        partner_xxhash_list: [partner_xxhash],
        period: settings.period,
        status_list_clients: activeFilterStatuses,
        target: showTree ? 'au_shop' : null,
      }),
    );
  }, []);

  if (error.code === 90) {
    return (
      <ErrorReport error={{ code: 90, isError: true, message: 'error' }} />
    );
  }

  return (
    <div className={css.reports}>
      <Helmet>
        <title>{t(`agencies_page.agencies_report`)}</title>
      </Helmet>
      {user?.partner.type === 'CLIENT' && (
        <NavigationHeader
          back={{
            title: t(`campaigns_page.campaign_settings.black_menu.Back`),
            to: `/main/campaigns`,
          }}
        />
      )}
      <div className={cn(css.header)}>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <Breadcrumbs type="Client" xxhash={partner_xxhash} />
          <div className={css.status}>
            <h1 className={css.title}>{t(`agencies_page.agencies_report`)}</h1>
            <div className={css.actions}>
              <div
                className={css.update}
                onClick={() => onUpdateStatistic(settings.period)}
              >
                <Tooltip bottom title={<FiRefreshCcw size={22} />}>
                  {t(`update_btn`)}
                </Tooltip>
              </div>
              {returnAgencyReportShowButton()}
              <div className={css.update}>
                <Tooltip
                  title={<FiDownload size={22} />}
                  bottom
                  isDisabled={disGetReport}
                  onClick={() => onGetReport()}
                >
                  {t(`download_btn`)}
                </Tooltip>
              </div>
              <div className={css.range}>
                <DateRangeFilter
                  range={{
                    from: parsingDate(settings.period.from).format(
                      'YYYY-MM-DD',
                    ),
                    to: parsingDate(settings.period.to).format('YYYY-MM-DD'),
                  }}
                  dateStart={parsingDate(
                    moment('1980-01-01').format('YYYY-MM-DD'),
                  ).toDate()}
                  dateEnd={parsingDate(new Date()).toDate()}
                  onSetDateRange={onUpdateDate}
                  initialTitleDay={settings.type}
                  hideAllTime
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(
          'container resized',
          {
            extend: isThemeExtended,
            full: isThemeFull,
          },
          css.filters,
        )}
      >
        {statusArr.map((status) => (
          <div
            onClick={() => statusFilterClickHandler(status.status)}
            className={cn(css.filters_item, {
              _isNoActive: !activeFilterStatuses.includes(status.status),
            })}
            key={status.title}
          >
            {status.title}
          </div>
        ))}
      </div>
      <div
        className={cn('container resized', {
          extend: isThemeExtended,
          full: isThemeFull,
        })}
      >
        <div className={css.content}>
          <GeneralReportTable
            tableTitle={t('campaigns_page.campaign_settings.reports.stat_tit')}
            isLoadingTableData={isLoadingClientsStat}
            columns={columns}
            totalValues={totalValues}
            transformedStatistic={transformedStatistic}
            mode="agency"
            expandable
            showAuTree={showTree}
          />
        </div>
      </div>
    </div>
  );
};

export default AgencyReport;
