import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import css from './styles.module.scss';
import { RNavigation } from '../../../types/general';

const SwitchItem: FC<RNavigation> = ({
  showNavigation,
  prevLink,
  nextLink,
  isLoading,
  count,
  isDisableLink,
  onCloseSearch,
}) => {
  if (isLoading) {
    return (
      <div className={css.switch}>
        <div className={cn('load-item', css.lodaing)} />
      </div>
    );
  }
  if ((count && isLoading) || showNavigation) {
    return (
      <div className={css.switch}>
        <Link
          onClick={() => {
            if (onCloseSearch) {
              onCloseSearch();
            }
          }}
          to={prevLink}
          className={cn(css.button, {
            isDisabled: isLoading && !prevLink || isDisableLink.prev,
          })}
        >
          <FiChevronLeft size={20} />
        </Link>

        <Link
          onClick={() => {
            if (onCloseSearch) {
              onCloseSearch();
            }
          }}
          to={nextLink}
          className={cn(css.button, {
            isDisabled: isLoading && !nextLink || isDisableLink.next,
          })}
        >
          <FiChevronRight size={20} />
        </Link>
      </div>
    );
  }
  return <></>;
};

export default SwitchItem;
