import { useFormik, FormikErrors, FormikProps } from 'formik';
import { useDispatchApp } from 'redux/rootSelectors';
import { useHistory } from 'react-router';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { useState } from 'react';
import { FormikValues, TAPIUser, editParams, editPayload } from '../types';
import { editAPIUser } from '../actions';
import i18n from "../../../../i18n";
import { useAPIUsersInfo } from '../selectors';

interface PEditUserFormik {
  currentUser: TAPIUser | undefined,
}

interface REditUserFormik {
  formik: FormikProps<FormikValues>,
  tagValidationError: string,
}

const useEditUserFormik = ({ currentUser }: PEditUserFormik): REditUserFormik => {

  const dispatch = useDispatchApp();

  const { data: usersAll } = useAPIUsersInfo();
  const history = useHistory();

  const [tagValidationError, setTagValidationError] = useState<string>('');

  const goBack = () => {
    history.push('/control_panel/users_api');
  };

  const createInitialValues = (): FormikValues => {
    if (!currentUser)
      return {
        email: '',
        data: {
          email: '',
          password: '',
          partners: [],
          tags: [],
          groups: [],
          methods: [],
          isActive: true,
        },
      };

    const partners = currentUser.partners.map((partner) => ({
      label: partner.title,
      value: partner.xxhash,
    }));

    const groups = currentUser.groups.map((group) => ({
      label: group.title,
      value: String(group.id),
    }));

    const tags = currentUser
      ? Object.values(currentUser.tags).map((tag) => tag.title)
      : [];

    return {
      email: currentUser.email || '',
      data: {
        email: currentUser.email || '',
        password: '',
        partners: partners || [],
        tags: tags || [],
        groups: groups || [],
        methods: currentUser?.methods || [],
        isActive: currentUser?.isActive,
      },
    };
  };

  const initialValues = createInitialValues();

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};

    if (!values.data?.partners?.length) {
      if (!errors.data) {
        errors.data = {};
      }
      errors.data.partners = i18n.t(`creative.creative_requare`);
    }


    if (values.data?.password?.length && values.data?.password?.length <= 3) {
      if (!errors.data) {
        errors.data = {};
      }
      errors.data.password = i18n.t(`users_api.passwordHelp`);
    }

    if (!values.data?.email?.length) {
      if (!errors.data) {
        errors.data = {};
      }
      errors.data.email = i18n.t(`creative.creative_requare`);
    }

    if (values.data?.email?.trim() !== values.email && usersAll?.find(item => item.email === values.data?.email?.trim())) {
      if (!errors.data) {
        errors.data = {};
      }
      errors.data.email = i18n.t(`form.common.email_exist`);
    }

    return errors;
  };

  const formik = useFormik<FormikValues>({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: (values) => {
      const { groups, partners } = values.data;

      const data: editParams['data'] | false = objectOptimizationWithFormik(
        initialValues.data,
        values.data,
      );

      if (data && data.groups) {
        data.groups = groups?.map((group) => +group.value);
      }
      if (data && data.partners) {
        data.partners = partners?.map((partner) => partner.value);
      }

      const params: editPayload = {
        email: values.email,
        data: data || {},
        callbacks: {
          goBack,
          onError: setTagValidationError,
        },
      };

      dispatch(editAPIUser(params));
    },
  });

  return {
    formik,
    tagValidationError,
  };
};

export default useEditUserFormik;