/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { FiSearch, FiX } from 'react-icons/fi';
import { useUserTheme } from 'domains/user/model/selectors';
import useSearchField from 'hooks/useSearchField';
import DateRangeFilter from 'components/DateRangeFilter';
import { parsingDate } from 'utils/momentParse';
import { KeysSettings, useStateValue } from 'utils/context';
import sub from 'date-fns/sub';
import ElementsNone from 'components/ElementsNone';
import {
  ElementsNoneType,
  HistorySelectedField,
  SelectField,
} from 'types/general';
import Tooltip from 'components/UI/Tooltip';
import { Helmet } from 'react-helmet';
import { useOnScroll } from 'hooks/useOnScroll';
import { useSubstitutionOfVoids } from 'utils/SubstitutionOfVoids';
import Input from 'components/UI/Input/input';
import PreloaderList from 'components/Preloaders/List';
import {
  filterActionsOptions,
  filterObjectOptions,
  filterParametrsChangeOptions,
} from 'domains/campaigns/model/shared';
import { debounce } from 'lodash';
import { useTranslateConstObject } from 'utils/useTranslateConstObject';
import moment from 'moment';
import css from './styles.module.scss';
import HistoryItem from './Item';
import { ReactComponent as ShowSvg } from './icons/show.svg';
import { ReactComponent as HideSvg } from './icons/hide.svg';
import { useHistoryData } from '../model/hooks/useHistoryData';
import i18n from '../../../i18n';
import { useHistoryDataSelector } from '../selectors';
import { ReactComponent as LimitListSvg } from './icons/limit.svg';
import HistoryFilter, { IDispatchHistoryParametres } from './HistoryFilter';
import SelectedFilters from './SelectedFilters';
import { IHistoryFilter } from '../types';

const HistoryChanges: FC = () => {
  const { isThemeExtended, isThemeFull } = useUserTheme();
  // ссылки на элементы поиска для скрытия по клику вне поля
  const searchRef = useRef<HTMLInputElement>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  // данные полученные из контекста для фильтра по периоду
  const { state } = useStateValue();
  const settings = state.settings[KeysSettings.HISTORY];
  // стейт для кнопки сворачивающей\разворачивающий все блоки каждого элемента истории
  const [isCollapseAll, setisCollapseAll] = useState<boolean>(false);
  // стейт, чтобы показать поле для ввода ограничения по выводу строк в таблице
  const [isShowLimitInput, setIsShowLimitInput] = useState<boolean>(false);
  // массив названия столбцов для хедера истории

  const historyHeader = useMemo(
    () => [
      '№',
      i18n.t(`history_changes_page.header_date`),
      i18n.t(`history_changes_page.header_who`),
      i18n.t(`history_changes_page.header_campaign`),
      i18n.t(`history_changes_page.header_creative`),
      i18n.t(`history_changes_page.header_parametr`),
      i18n.t(`history_changes_page.header_changes`),
    ],
    [i18n.language],
  );

  /* Нужно, чтобы при смене языка и возврате на предыдущую страницу, применялся перевод. 
  Из-за того, что объект не в компоненте, перевод делается один раз, на момент импорта для того языка, что установлен, а при смене языка ничего не меняется */
  const filterObjectOptionsTranslate: HistorySelectedField[] =
    useTranslateConstObject(filterObjectOptions);
  const filterActionsOptionsTranslate: HistorySelectedField[] =
    useTranslateConstObject(filterActionsOptions);
  const filterParametrsChangeOptionsTranslate: SelectField[] =
    useTranslateConstObject(filterParametrsChangeOptions);

  const historyParams = localStorage.getItem('localHistoryParams');
  const parsedHistoryParams: IDispatchHistoryParametres = historyParams && JSON.parse(historyParams);
  const isLocalHistory = localStorage.getItem('isLocalHistory');

  const returnLimitTo = (): string | number => {
    if (isLocalHistory && parsedHistoryParams && parsedHistoryParams.limit) {
      return parsedHistoryParams.limit.to
    }
      const limitsFromLocalStorage = localStorage.getItem('limits');
      const defaultLimit = settings.limit.to;
      return limitsFromLocalStorage || defaultLimit;
  }

  const returnInitialTitle = () => {
    if (isLocalHistory && parsedHistoryParams !== null && parsedHistoryParams.period !== null) {
      return 'period';
    }
    return settings.period.to === moment(new Date()).format('YYYY-MM-DD')
      ? settings.type
      : 'period';
  };

  // лимит строк либо с localStorage, либо если перешли с РК\креатива
  const [limitItems, setLimitItems] = useState<number | string>(returnLimitTo);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('keyup', (e: any) => {
        if (e.key === 'Enter') {
          setIsShowLimitInput(false);
        }
      });
      inputRef.current.addEventListener('blur', () => {
        setIsShowLimitInput(false);
      });
    }

    return () => {
      inputRef.current?.removeEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          setIsShowLimitInput(false);
        }
      });
      inputRef.current?.removeEventListener('blur', () => {
        setIsShowLimitInput(false);
      });
    };
  }, []);

  let hFilters;

  // функционал поисковой строки
  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef, searchWrapperRef, hFilters, +limitItems);
  // функционал по работе с получением данных истории и фильрации
  const {
    historyFilters,
    isLoading,
    filterData,
    applyHistoryFilters,
    setHistoryFilters,
    setLastHistoryFilters,
    getHistoryItemsByLimits,
  } = useHistoryData(
    settings.period,
    settings.type,
    search,
    setisCollapseAll,
    +limitItems,
  );
  hFilters = historyFilters;
  const { fixed } = useOnScroll(110);
  const { data } = useHistoryDataSelector();
  const [processedFilters, setProcessedFilters] = useState<
    HistorySelectedField[]
  >([]);
  const [isFilterDelete, setIsFilterDelete] = useState<boolean>(false);

  const delayedDispatchLimits = useRef<ReturnType<typeof debounce> | null>(
    null,
  );

  const checkIsActiveDebounceDelay = (value: number) => {
    if (delayedDispatchLimits.current) {
      delayedDispatchLimits.current.cancel();
    }

    delayedDispatchLimits.current = debounce(() => {
      getHistoryItemsByLimits(value);
    }, 700);

    delayedDispatchLimits.current();
  };

  const changeLimitItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (
      value === '' ||
      (value !== '0' && !Number.isNaN(+value) && +value >= 0 && +value <= 3000)
    ) {
      localStorage.setItem('limits', String(value));
      setLimitItems(value.toString());
      checkIsActiveDebounceDelay(+value);
    }
  };

  // Получает ключ и пушит в итоговый массив с фильтрами переведенный вариант
  const pushTranslatedFilter = (
    string: string,
    array: HistorySelectedField[],
  ) => {
    const translateObj = [
      ...filterObjectOptionsTranslate,
      ...filterActionsOptionsTranslate,
      ...filterParametrsChangeOptionsTranslate,
    ];
    const foundObj = translateObj.find((obj) => obj.value === string);
    if (foundObj) {
      array.push(foundObj);
    } else {
      array.push({ value: string, label: string });
    }
  };

  // Превращает фильтры в массив строк
  const filtersValueToString = (filters: IHistoryFilter): void => {
    const filtersArray: HistorySelectedField[] = [];
    const objToArray = Object.values(filters);
    objToArray.forEach((filter) => {
      if (Array.isArray(filter)) {
        filter.forEach((f) => {
          pushTranslatedFilter(f, filtersArray);
        });
      }
      if (typeof filter !== 'object' && filter !== '' && filter !== undefined) {
        pushTranslatedFilter(filter, filtersArray);
      }
    });
    setProcessedFilters(filtersArray);
  };

  const deleteFilterByValue = (
    val: string | null,
    object: IHistoryFilter,
  ): void => {
    if (val) {
      const updatedObj = { ...object };

      Object.keys(updatedObj).forEach((key) => {
        if (
          updatedObj[key] === val ||
          (Array.isArray(updatedObj[key]) &&
            updatedObj[key].some((item: string) => item === val))
        ) {
          delete updatedObj[key];
          setIsFilterDelete(true);
        }
      });

      if (parsedHistoryParams && isLocalHistory) {
        localStorage.setItem('localHistoryParams', JSON.stringify(updatedObj));
        return;
      }
      setHistoryFilters(updatedObj);
    }
  };

  useEffect(() => {
    if (isFilterDelete) {
      filtersValueToString(historyFilters);
      setIsFilterDelete(false);
    }
  }, [historyFilters]);

  // заполняет пустоту, когда фиксируется header и заголовок таблицы, чтобы не скакал скролл
  const { fakeHeader, fakePart, fakeHeaderRef, fakePartRef } =
    useSubstitutionOfVoids({ fixed });
  return (
    <div
      className={cn(css.history, {
        isFixedHeader: fixed,
      })}
    >
      <Helmet>
        <title>{i18n.t(`history_changes_page.title`)}</title>
      </Helmet>
      {fakeHeader}
      <div className={css.header} ref={fakeHeaderRef}>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div
            className={cn(css.headeRow, {
              _isActiveSearch: toggleSearch,
            })}
          >
            <h1 className={css.title}>
              {i18n.t(`history_changes_page.title`)}
            </h1>
            <div className={css.actions}>
              <div ref={searchWrapperRef} className={css.search}>
                <div className={css.icon} onClick={onOpenSearch}>
                  <FiSearch size={24} />
                </div>
                <div className={css.input}>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="internal id | xxhash | title"
                    value={search}
                    onChange={onChangeSearch}
                    ref={searchRef}
                  />
                </div>
                <div className={css.close} onClick={onCloseSearch}>
                  <FiX size={24} />
                </div>
              </div>
              <div className={css.filter}>
                <HistoryFilter
                  isFilterDelete={isFilterDelete}
                  setIsFilterDelete={setIsFilterDelete}
                  filtersValueToString={filtersValueToString}
                  historyFilters={historyFilters}
                  topPositionRef={fakeHeaderRef}
                  setHistoryFilters={setHistoryFilters}
                  commonSearch={false}
                  applyHistoryFilters={applyHistoryFilters}
                  setLastHistoryFilters={setLastHistoryFilters}
                  changeTopPositionParametr={fixed}
                  changeTopPositionRef={fakePartRef}
                  selectedFilterTotal={processedFilters}
                />
              </div>

              <div
                className={cn(css.limits, {
                  _isInput: isShowLimitInput,
                })}
                onClick={
                  !isShowLimitInput
                    ? () => setIsShowLimitInput(!isShowLimitInput)
                    : () => null
                }
              >
                <Tooltip bottom title={<LimitListSvg />}>
                  {i18n.t(`history_changes_page.rows_limit`)}
                </Tooltip>
                <div className={css.limits_value}>
                  {/* Добавить InputAutoSave */}
                  <div className={css.limits_number}>{limitItems}</div>
                  <div className={css.limits_input}>
                    <Input
                      innerRef={inputRef}
                      inputAttributes={{
                        value: limitItems,
                        onChange: changeLimitItems,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={css.expandBtn}
                onClick={() => setisCollapseAll(!isCollapseAll)}
              >
                <Tooltip
                  bottom
                  title={isCollapseAll ? <HideSvg /> : <ShowSvg />}
                >
                  {isCollapseAll
                    ? i18n.t(`history_changes_page.compact_view`)
                    : i18n.t(`history_changes_page.full_view`)}
                </Tooltip>
              </div>
              <div className={css.range}>
                <DateRangeFilter
                  range={{
                    from: isLocalHistory && parsedHistoryParams && parsedHistoryParams.period && parsedHistoryParams.period.from 
                      ? parsedHistoryParams.period.from 
                      : parsingDate(settings.period.from).format('YYYY-MM-DD'),
                    to: isLocalHistory && parsedHistoryParams && parsedHistoryParams.period && parsedHistoryParams.period.to 
                      ? parsedHistoryParams.period.to 
                      : parsingDate(settings.period.to).format('YYYY-MM-DD'),
                  }}
                  dateStart={parsingDate(
                    sub(new Date(), { years: 1 }),
                  ).toDate()}
                  dateEnd={parsingDate(new Date()).toDate()}
                  onSetDateRange={(period, type) => filterData(period, type)}
                  initialTitleDay={returnInitialTitle()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.content}>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div className={css.table}>
            {fakePart}
            <div className={css.fixedHeader} ref={fakePartRef}>
              <div className={css.selectedFilters}>
                {processedFilters.map((filter, index) => (
                  <SelectedFilters
                    filterObj={filter}
                    value={filter.label}
                    tooltip={filter.label}
                    historyFilters={historyFilters}
                    deleteFilterByValue={deleteFilterByValue}
                    key={`${filter.value + String(index)}`}
                  />
                ))}
              </div>
              <div className={css.table__header}>
                {historyHeader.map((item) => (
                  <div key={item} className={css.table__headerItem}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            {isLoading ? (
              <PreloaderList type="history" />
            ) : !data?.length && !isLoading ? (
              <ElementsNone type={ElementsNoneType.HISTORY} search />
            ) : (
              <div className={css.table__body}>
                {data?.map((item, index) => (
                  <HistoryItem
                    data={item}
                    index={index}
                    isCollapseAll={isCollapseAll}
                    // eslint-disable-next-line react/no-array-index-key
                    key={item.datetime.date + index}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryChanges;
