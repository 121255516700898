/* eslint-disable no-underscore-dangle */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { useDispatchApp } from 'redux/rootSelectors';
import { onSetTargeting } from 'domains/target/actions';
import { setTargetingsRequest } from 'domains/creative/reduser';
import { Ttarget } from 'domains/campaign/types';
import {
  GeoResult,
  PSetTargeting,
  RGetSettings,
  TLeftD,
  TTargeting_data,
  TargetKey,
  Tree,
} from '../types';
import { arTargetTree } from '../shared';
import { fetchGeoPopular } from '../actions';

type UseOnSaveTargetHandlerArgs = {
  tree: Tree[];
  itemsOnSave: GeoResult[];
  leftD: TLeftD[];
  title: string;
  type: TargetKey;
  settings: RGetSettings | null;
  isCreative: boolean;
  isSourceLike: boolean;
  isSourceLikeLogic: boolean;
  statisticsIsGroup: boolean;
  deletedItemsArr: React.MutableRefObject<string[]>;
  id: string;
  isInvertMode: boolean;
  itemsServer: Ttarget;
  use_inherit_settingsState: boolean;
  use_inherit_settings: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  resetTargetError: () => void;
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  clearDeletedArr: () => void;
  touchedItemsToSave: string[];
  setTouchedItemsToSave: React.Dispatch<React.SetStateAction<string[]>>;
  targetUserSettingsData: Record<TargetKey, Ttarget> | null;
};

type UseOnSaveTargetHandlerReturn = {
  onSave: () => void;
};

type TUseOnSaveTargetHandler = (
  args: UseOnSaveTargetHandlerArgs,
) => UseOnSaveTargetHandlerReturn;

export const useOnSaveTargetHandler: TUseOnSaveTargetHandler = ({
  id,
  type,
  tree,
  title,
  leftD,
  itemsOnSave,
  settings,
  isCreative,
  itemsServer,
  isSourceLikeLogic,
  isSourceLike,
  deletedItemsArr,
  statisticsIsGroup,
  isInvertMode,
  use_inherit_settingsState,
  use_inherit_settings,
  setIsLoading,
  resetTargetError,
  setShowSaveButton,
  clearDeletedArr,
  touchedItemsToSave,
  setTouchedItemsToSave,
  targetUserSettingsData,
}) => {
  const dispatch = useDispatchApp();

  const { i18n } = useTranslation();

  const checkParents = (childrenArr: TLeftD[]): boolean =>
    childrenArr.every((child) => {
      if (child.children?.length) {
        return Boolean(checkParents(child.children));
      }
      return Boolean(child.is_checked);
    });

  // Функция проходит по всему дереву итемом и пушит в одномерный массив для отправки все итемы у которых item?._isSettings === true
  const recursiveLeftDOnSave = (item: TLeftD, resultArr: TLeftD[]) => {
    const isStatic = settings?.type ? [1, 5].includes(settings.type) : false;
    if (
      // eslint-disable-next-line no-underscore-dangle
      (item?._isSettings && !item?.children?.length && isStatic) ||
      // eslint-disable-next-line no-underscore-dangle
      (item?._isSettings && (!isStatic || statisticsIsGroup))
    ) {
      resultArr.push(item);
    }

    if (item?.children && item?.children.length && !statisticsIsGroup) {
      if (isSourceLikeLogic) {
        const hasTouchedChildrens = item.children.some(
          (child) => child._isSettings,
        );
        if (item._isSettings) {
          resultArr.push(item);
        } else if (checkParents(item.children) && hasTouchedChildrens) {
          resultArr.push({
            ...item,
            is_checked: true,
          });
        }
        if (
          item.children.every((child) => !child.is_checked) &&
          hasTouchedChildrens
        ) {
          resultArr.push({
            ...item,
            is_checked: false,
          });
        }
      }

      item.children.forEach((child) => recursiveLeftDOnSave(child, resultArr));
    }
  };

  /* Добавляет чекнутые итемы с лимитами в объект для сохранения */
  const recursiveScanLimits = (
    dataTree: Tree[],
    items: TTargeting_data['items'],
  ) => {
    dataTree.forEach((item) => {
      /* const isChecked = verifyCheckbox(item); */
      const { limits } = item;
      // && Object.keys(limits).length
      if (item.isSettings) {
        // eslint-disable-next-line no-param-reassign
        items[item.geoname_id] =
          typeof limits === 'object'
            ? {
                is_checked: item.isChecked !== 'disable',
                name: i18n.language === 'ru' ? item.name_ru : item.name_en,
                bid_rate: item.bid_rate,
                limits,
              }
            : {
                is_checked: item.isChecked !== 'disable',
                name: i18n.language === 'ru' ? item.name_ru : item.name_en,
                bid_rate: item.bid_rate,
              };
      }
      if (item.children && item.children.length !== 0) {
        recursiveScanLimits(item.children, items);
      }
    });
  };

  const recurseAddTreeItems = (
    p: GeoResult,
    items: TTargeting_data['items'],
  ) => {
    if (!items[p.geoname_id]) {
      // eslint-disable-next-line no-param-reassign
      items[p.geoname_id] = {
        is_checked: p.isChecked,
        name: i18n.language === 'ru' ? p.name_ru : p.name_en,
      };
    }
    if (p.childDisable && p.childDisable.length) {
      p.childDisable.forEach((child) => {
        recurseAddTreeItems(child, items);
      });
    }
  };

  const removeSettingsItems = (obj: TLeftD) => {
    const result: string[] = [];
    if (targetUserSettingsData) {
      Object.keys(targetUserSettingsData[type].items).forEach((t) => {
        if (t === obj.key || touchedItemsToSave.includes(t)) {
          result.push(t);
        }
      });
    }
    deletedItemsArr.current.push(...new Set(result));
  };

  const compareObjectValues = (parent: TLeftD, children: TLeftD[]): boolean => {
    // eslint-disable-next-line no-param-reassign
    parent = {
      ...parent,
      bid_rate: parent.bid_rate === '' ? 1 : parent.bid_rate,
      bid_start: parent.bid_start === '' ? 1 : parent.bid_start,
    }
    if (type === 'source') {
      // Проверяем, что все ключи у детей соответствуют ключам родителя
      return children.every(
        (child) =>
          String(child.bid_rate === '' ? 1 : child.bid_rate) === String(parent.bid_rate) &&
          String(child.maxbid) === String(parent.maxbid) &&
          String(child.bid_start=== '' ? 0 : child.bid_start) === String(parent.bid_start) &&
          child.is_checked === parent.is_checked &&
          child.type === parent.type,
      );
    }
    return children.every(
      (child) =>
        String(child.bid_rate === '' ? 1 : child.bid_rate) === String(parent.bid_rate) &&
        child.is_checked === parent.is_checked,
    );
  };

  const isDifferentChild = (obj: TLeftD, child: TLeftD) => {
    const parentMaxbid = obj.maxbid === '' ? 0 : obj.maxbid;
    const parentBidstart = obj.bid_start === '' ? 0 : obj.bid_start;
    const parentBidrate = obj.bid_rate === '' ? 1 : obj.bid_rate;
    const parentType = obj.type;

    if (type === 'source') {
      if (
        String(child.maxbid === '' ? 0 : child.maxbid) !== String(parentMaxbid) ||
        String(child.bid_start === '' ? 0 : child.bid_start) !== String(parentBidstart) ||
        String(child.bid_rate === '' ? 1 : child.bid_rate) !== String(parentBidrate) ||
        child.type !== parentType
      ) {
        return child;
      }
      return undefined;
    }

    if (
      String(child.bid_rate === '' ? 1 : child.bid_rate) !== String(parentBidrate)
    ) {
      return child;
    }
    return undefined;
  };

  // Сравниваем детей с элементами в настройках и смотрим были ли изменения
  const isChildChanged = (child: TLeftD): boolean => {
    if (targetUserSettingsData) {
      Object.keys(targetUserSettingsData[type].items).forEach((t) => {
        if (t === child.key && (
          targetUserSettingsData[type].items[t].bid_rate !== child.bid_rate || 
          targetUserSettingsData[type].items[t].bid_start !== child.bid_start ||
          targetUserSettingsData[type].items[t].maxbid !== child.maxbid
        )) {
          return true;
        }
        return false;
      });
    }
    return false;
  }

  // Проверяем трогали ли дочек
  const isChildTouched = (childArr: TLeftD[]) => {
    let filteredArr: TLeftD[] = [];
    filteredArr = childArr.filter(ch => touchedItemsToSave.includes(ch.key));
    return filteredArr;
  };

  const createParentObjIfChildrensAreSame = (obj: TLeftD) => {
    if (obj.children) {
      const firstisChecked = obj.children[0].is_checked;
      const firstBidRate = obj.children[0].bid_rate;
      const firstMaxBid = obj.children[0].maxbid;
      const firstBidStart = obj.children[0].bid_start;
      const firstBidType = obj.children[0].type;

      if (type === 'source') {
        return {
          ...obj,
          is_checked: obj.children.every(ch => firstisChecked === ch.is_checked) ? firstisChecked : obj.is_checked,
          bid_rate: obj.children.every(ch => firstBidRate === ch.bid_rate) ? firstBidRate : obj.bid_rate,
          maxbid: obj.children.every(ch => firstMaxBid === ch.maxbid) ? firstMaxBid : obj.maxbid,
          bid_start: obj.children.every(ch => firstBidStart === ch.bid_start) ? firstBidStart : obj.bid_start,
          type: obj.children.every(ch => firstBidType === ch.type) ? firstBidType : obj.type,
        }
      }
      return {
        ...obj,
        is_checked: obj.children.every(ch => firstisChecked === ch.is_checked) ? firstisChecked : obj.is_checked,
        bid_rate: obj.children.every(ch => firstBidRate === ch.bid_rate) ? firstBidRate : obj.bid_rate,
      }
    }
    return obj;
  }

  const filteredResultArr = (arr: TLeftD[]): TLeftD[] => {
    const filteredArray: TLeftD[] = [];
    arr.forEach((obj) => {
       // Проверяем, есть ли у объекта массив children
      if (obj.children) {
        // Проверяем, все ли дети имеют одинаковые данные
        if (
          compareObjectValues(obj, obj.children) &&
          isChildTouched(obj.children).length === 0
        ) {
          // Если массив потроганных элементов пуст, значит трогали только родителя, то удаляем всех дочек из настроек и отправляем родителя
          obj.children.forEach((o) => removeSettingsItems(o));
          filteredArray.push(obj);
        } else if (
          !compareObjectValues(obj, obj.children) &&
          isChildTouched(obj.children).length > 0
        ) {
          // Пушим в массив родителя и дочек которых потрогали
          obj.children.forEach((o) => !isDifferentChild(obj, o) !== undefined && removeSettingsItems(o));
          const changedParent = createParentObjIfChildrensAreSame(obj);
          filteredArray.push(changedParent);
          if (!compareObjectValues(changedParent, obj.children)) {
            isChildTouched(obj.children).forEach((ch) => filteredArray.push(ch));
          }
          
        } else if (compareObjectValues(obj, obj.children)) {
          obj.children.forEach((o) => removeSettingsItems(o));
          filteredArray.push(obj);
        } else {
          // Пушим в результирующий массив только элементы из основного массива, которые равны элементам children
          if (
            // Если элемент потрогали и все дочки is_checked = false то отправляем родителя
            // Нужно продумать условие и переписать
            touchedItemsToSave.includes(obj.key) ||
            obj.children.every((ch) => ch.is_checked === false) ||
            obj.children.every((ch) => ch.is_checked === true)
          ) {
            filteredArray.push(obj);
          }
          obj.children.forEach((o) => {
            if (
              // Удаляем из настроек одинаковых дочек, оставляем измененную
              (isDifferentChild(obj, o) &&
                o.key !== isDifferentChild(obj, o)?.key) ||
              (targetUserSettingsData &&
                Object.keys(targetUserSettingsData[type].items).includes(
                  o.key,
                ) &&
                !touchedItemsToSave.includes(o.key))
            ) {
              removeSettingsItems(o);
            }
          });
          // Пушим в массив только те элементы, которые были изменены или потроганы
          filteredArray.push(
            ...obj.children.filter(
              (o) =>
                isChildChanged(o) ||
                touchedItemsToSave.includes(o.key) ||
                isDifferentChild(obj, o) !== undefined,
            ),
          );
        }
      } else {
        // Пушим все остальные элементы, на случай если были потроганы несколько групп и элементы являются дочками других родителей
        const existsInChildren = arr.filter(
          (other) =>
            other.children &&
            other.children.find((child) => child.key === obj.key),
        );
        if (existsInChildren.length === 0) {
          if (obj.children !== undefined) {
            removeSettingsItems(obj);
          }
          // Если такого объекта нет в children других объектов, пушим этот объект
          filteredArray.push(obj);
        }
      }
    });
    return filteredArray;
  };

  const createOnSaveObjForTree = (items: TTargeting_data['items']) => {
    recursiveScanLimits(tree, items);
    itemsOnSave.forEach((item) => recurseAddTreeItems(item, items));
  };

  const createOnSaveObjForGrid = (items: TTargeting_data['items']) => {
    const resultArr: TLeftD[] = [];
    leftD.forEach((item) => recursiveLeftDOnSave(item, resultArr));
    filteredResultArr(resultArr).forEach((i) => {
      // Возможно условие уже не понадобится ибо по дефолту стоит 1
      if (i.bid_rate === '') {
        // eslint-disable-next-line no-param-reassign
        items[i.key] = { is_checked: i.is_checked, bid_rate: 1};
      } else {
        // eslint-disable-next-line no-param-reassign
        items[i.key] = { is_checked: i.is_checked, bid_rate: i.bid_rate };
      }
      const obj = items[i.key];
      if (isSourceLike) {
        obj.type = i.type;
        obj.bid_start = i.bid_start;
        obj.maxbid = i.maxbid;
      }
      if (type === 'au' || type === 'au_shop') {
        obj.hour = i.hour;
      }
      if (type === 'keywords') {
        obj.value = i.value;
      }
    });
  };

  const onSave = useCallback(() => {
    const items: TTargeting_data['items'] = {};
    if (arTargetTree.includes(type)) {
      createOnSaveObjForTree(items);
    } else {
      createOnSaveObjForGrid(items);
    }

    const dataSend: PSetTargeting = {
      xxhash: id,
      target_key: type,
      targeting_data: {
        is_invert_mode: isInvertMode,
        items,
      },
    };

    const isEmptyData = objectOptimizationWithFormik(itemsServer.items, items);

    const needSave =
      isEmptyData ||
      use_inherit_settingsState !== !use_inherit_settings ||
      isInvertMode !== itemsServer.settings?.is_invert_mode;

    // eslint-disable-next-line no-param-reassign
    deletedItemsArr.current = [...new Set(deletedItemsArr.current)];

    if (needSave) {
      setIsLoading(true);
      if (isCreative) {
        dispatch(
          setTargetingsRequest({
            ...dataSend,
            use_inherit_settings: use_inherit_settingsState,
            blockTitle: title,
            setLoading: setIsLoading,
            setEdit: setShowSaveButton,
            clearDeletedArr,
            deletedItemsArr: deletedItemsArr.current,
          }),
        );
      } else {
        dispatch(
          onSetTargeting({
            ...dataSend,
            setEdit: setShowSaveButton,
            setLoading: setIsLoading,
            clearDeletedArr,
            deletedItemsArr: deletedItemsArr.current,
            blockTitle: title,
          }),
        );
      }
      if (type === 'geo_id') {
        dispatch(fetchGeoPopular());
      }
    } else {
      resetTargetError();
      setShowSaveButton(false);
      clearDeletedArr();
    }
    setTouchedItemsToSave([]);
  }, [
    leftD,
    isInvertMode,
    use_inherit_settingsState,
    itemsOnSave,
    touchedItemsToSave,
  ]);

  return {
    onSave,
  };
};;
