/* eslint-disable jsx-a11y/anchor-is-valid */
import InfoBlock from 'components/UI/InfoBlock';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import cn from 'classnames';
import { FormikProps } from 'formik';
import { LinkOption } from 'domains/creative/types';
import { checkAvalibleFieldsCount } from 'domains/creative/creatives/hooks/MassEdit';
import css from '../styles.module.scss';
import SettingsList from '../SettingsList';

interface Props {
  formik: FormikProps<Record<string, string | null>>;
  linksOptions: LinkOption[];
  checkedTypes: string[];
  activeFields: string[];
  setActiveFields: React.Dispatch<React.SetStateAction<string[]>>;
}

const notLinks = ['iab', 'erid', 'language'];

const LinksView: FC<Props> = ({
  formik,
  linksOptions,
  checkedTypes,
  activeFields,
  setActiveFields,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const copy = { ...formik.values };
    Object.keys(copy).forEach((key) => {
      if (!activeFields.includes(key) && !notLinks.includes(key)) {
        delete copy[key];
      }
    });
    formik.setValues(copy);
  }, [activeFields]);

  return (
    <>
      <InfoBlock
        color="BLUE"
        text={
          <>
            <div>
              {t(`creative.set_url_incl_links`)} <a href="https://terratraf.readme.io/docs/td-creatives-bulk-editing" target='_blank' rel="noreferrer">{t(`more_btn`)}</a>
            </div>
            <div>{t(`creative.liks_stays`)}</div>
          </>
        }
      />

      <div className={css.add_panel}>
        <div className={css.settings_list}>
          <SettingsList
            activeFields={activeFields}
            formik={formik}
            setActiveFields={setActiveFields}
            linksOptions={linksOptions}
            checkedTypes={checkedTypes}
          />
        </div>
        <div
          className={cn(css.add_btn, {
            [css.disable]: !checkAvalibleFieldsCount(
              linksOptions,
              checkedTypes,
              activeFields,
            ),
          })}
          onClick={() => {
            setActiveFields((prev) => [...prev, '']);
          }}
        >
          <FiX size={16} />
          <span>{t(`creative.add_url`)}</span>
        </div>
      </div>
    </>
  );
};

export default LinksView;
