/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { LinkOption } from 'domains/creative/types';
import {
  checkAvalibleTargetTypes,
  createCurrentOptions,
} from 'domains/creative/creatives/hooks/MassEdit';
import EditLinkItem from '../EditLinkItem';
import i18n from '../../../../../i18n';

interface ISettingsListProps {
  setActiveFields: React.Dispatch<React.SetStateAction<string[]>>;
  activeFields: string[];
  checkedTypes: string[];
  formik: FormikProps<Record<string, string | null>>;
  linksOptions: LinkOption[];
}

const SettingsList: FC<ISettingsListProps> = ({
  setActiveFields,
  activeFields,
  checkedTypes,
  linksOptions,
  formik,
}) => {

  const targetNames = useMemo(() => ({
    TEASER: i18n.t(`creative.create_menu.TEASER`),
    HTML: i18n.t(`creative.create_menu.HTML`),
    VIDEO_VAST: i18n.t(`creative.create_menu.VIDEO_VAST`),
    VIDEO_INLINE: i18n.t(`creative.create_menu.VIDEO_INLINE`),
    MULTI_FORMAT: i18n.t(`creative.create_menu.MULTI_FORMAT`),
    video_html: i18n.t(`creative.create_menu.HTML_VIDEO`),
    COMPANION: i18n.t(`creative.create_menu.COMPANION`),
    CLICKUNDER: i18n.t(`creative.create_menu.CLICKUNDER`),
  }), []);

  const [currentListOptions] = useState(() =>
    createCurrentOptions(linksOptions, checkedTypes),
  );

  const fieldElements = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (fieldElements.current) {
      fieldElements.current.pop()?.scrollIntoView();
    }
  }, [fieldElements.current.length]);

  return (
    <>
      {activeFields.map((item, index) => (
        <EditLinkItem
          linkOptions={currentListOptions}
          fieldElements={fieldElements}
          formik={formik}
          key={index}
          index={index}
          avalibleTargetTypes={checkAvalibleTargetTypes({
            optionsList: currentListOptions,
            targetKey: item,
            targetNames,
          })}
          fieldName={item}
          activeFields={activeFields}
          setActiveFields={setActiveFields}
        />
      ))}
    </>
  );
};

export default SettingsList;
