import React, { FC, useEffect, useRef, useState } from 'react';
import { FiAlertCircle, FiRotateCcw, FiX } from 'react-icons/fi';
import Input from 'components/UI/Input/input';
import cn from 'classnames';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { FormikProps } from 'formik';
import { useValidateVendorLInk } from 'domains/creative/model/hooks/useValidateVendorLInk';
import NewSelect from 'components/UI/NewSelect';
import css from '../styles.module.scss';
import i18n from '../../../../../i18n';
import { ReactComponent as CleanSvg } from '../../../../../assets/clean.svg';

interface SelectOptions {
  value: string;
  label: string;
}

interface Props {
  linkOptions: SelectOptions[];
  index: number;
  fieldName: string;
  avalibleTargetTypes: string[];
  activeFields: string[];
  formik: FormikProps<Record<string, string | null>>;
  fieldElements: React.MutableRefObject<HTMLDivElement[]>;
  setActiveFields: React.Dispatch<React.SetStateAction<string[]>>;
}

const EditLinkItem: FC<Props> = ({
  linkOptions,
  index,
  fieldName,
  formik,
  fieldElements,
  activeFields,
  avalibleTargetTypes,
  setActiveFields,
}) => {
  const [cleanedLink, setCleanedLink] = useState<boolean>(
    formik.values[fieldName] === null,
  );

  const avalibleSelectOptions = linkOptions.filter(
    (item) => !activeFields.includes(item.value) || item.value === fieldName,
  );

  useEffect(() => {
    setCleanedLink(formik.values[fieldName] === null);
  }, [fieldName]);

  const { helper } = useValidateVendorLInk({
    formik: {
      link: formik.values.link,
      pixel: formik.values.pixel,
      pixel_additional: formik.values.pixel_additional,
      extension_link: formik.values.extension_link,
      js_code_audit: formik.values?.js_code_audit,
    },
    adomain: formik.values.adomain,
  });

  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!fieldRef.current) return;
    fieldElements.current.push(fieldRef.current);
  }, [fieldRef.current]);

  return (
    <div className={css.settings_item} ref={fieldRef}>
      <div className={css.settings_item_number}>{index + 1}</div>
      <div className={css.settings_item_select}>
        <NewSelect
          noOptionsMessage={() => i18n.t(`no_result`)}
          onCustomChange={(e) => {
            if (cleanedLink) {
              setCleanedLink(false);
            }
            setActiveFields((prev) => {
              const copy = [...prev];
              copy[index] = e;
              return copy;
            });
          }}
          selectAttributes={{
            isDisabled: false,
            value:
              fieldName === ''
                ? null
                : linkOptions.find((item) => item.value === fieldName),
            options: avalibleSelectOptions,
            placeholder: i18n.t(`creative.create_creative.select_from_list`),
          }}
          heightCustom={32}
          viewStyle='Horizontal'
        />
        {fieldName && (
          <TooltipPoratal
            hint={i18n.t(`creative.used_in_creatives`, {
              creos: avalibleTargetTypes.join(', '),
            })}
            white
            hintWidth={246}
          >
            <FiAlertCircle size={20} color="#FF9C08" />
          </TooltipPoratal>
        )}
      </div>
      <div className={css.settings_item_link}>
        <Input
          notField
          isLink={false}
          helper={helper()[fieldName]}
          error={!!formik.errors[fieldName]}
          errortext={formik.errors[fieldName]}
          disabled={cleanedLink || !fieldName}
          inputAttributes={{
            placeholder: cleanedLink
              ? i18n.t(`creative.the_field_be_deleted`)
              : i18n.t(`creative.enter_link`),
            name: fieldName,
            onChange: formik.handleChange,
            value: formik.values[fieldName] || '',
          }}
          theme="horizontal"
          isTextarea
          classname="exactly"
        />
      </div>
      <div className={css.settings_item_actions}>
        <div
          className={cn(css.clean, {
            _isDisabled: fieldName === '',
          })}
        >
          <TooltipPoratal
            hideHint={fieldName === ''}
            hint={
              cleanedLink
                ? i18n.t(`clients_page.client_edit.link_to_image_cancel`)
                : i18n.t(`clean_btn`)
            }
          >
            <div
              onClick={() => {
                if (!fieldName) return;
                const val = cleanedLink ? '' : null;
                setCleanedLink((prev) => !prev);
                formik.setFieldValue(fieldName, val);
              }}
            >
              {cleanedLink ? <FiRotateCcw size={18} /> : <CleanSvg />}
            </div>
          </TooltipPoratal>
        </div>

        <TooltipPoratal hint={i18n.t(`delete_btn`)}>
          <FiX
            size={16}
            onClick={() =>
              setActiveFields((prev) => prev.filter((_, i) => i !== index))
            }
          />
        </TooltipPoratal>
      </div>
    </div>
  );
};

export default EditLinkItem;
