import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { fetchAPIUsers, refreshTokenAPIUser } from "../actions";
import { useAPIUsersInfo } from "../selectors";
import useFetchUserFormData from "./useFetchUserFormData";
import { TAPIUser } from "../types";

interface RUseFetchUserFormData {
    isLoading: boolean;
    currentUser: TAPIUser | undefined;
    email: string;
    updateToken: () => void;
  }
  
  const useFetchUserEditFormData = (): RUseFetchUserFormData => {
    const dispatch = useDispatch();
    const { data: usersAll, isLoading: usersIsLoading } = useAPIUsersInfo();

    const location = useLocation()
    const paramHash = new URLSearchParams(location.search);

    const email = paramHash.get('email') || ''

    const { isLoading: isLoadingPartners } = useFetchUserFormData()

    const currentUser = usersAll?.find((someUser) => someUser.email === email);
  
    useEffect(() => {
        if (!usersAll) {
            dispatch(fetchAPIUsers({}));
          }
    }, [usersAll]);

    const updateToken = () => {
        dispatch(refreshTokenAPIUser({ email }));
      };
  
    return { 
        isLoading: isLoadingPartners || usersIsLoading,
        currentUser,
        email,
        updateToken,
    };
  };
  
  export default useFetchUserEditFormData;